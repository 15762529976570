<template>
  <div>
    <div class="btn">
      <el-button type="primary" class="control-item" icon="el-icon-back" @click="goBack">
        返回
      </el-button>
    </div>

    <video style="width: 100%" v-if="data.type === '3'" autoplay controlslist="nodownload"  ref="myVideo" controls
           :src="data.playUrls" ></video>
      <div v-if="data.type === '1'|| data.type === '3'" v-html="data.content" style="margin: 10px;" />

    <div v-if="data.type === '2'">
      <iframe
        v-if="!!data.content"
        :src="data.content"
        frameborder="0"
        style="width: 100%;height: 800px; margin: 10px;"
      />
    </div>
  </div>
</template>

<script>
import API from '@/api'
export default {
  name: "technicalSupportDetail",
  data() {
    return {
      data: {
        type: 0,
        content: 10,
        title: "",
        playUrls:''
      },
      vid_data: null, //视频信息
      numPages: 1,
    }
  },
  computed: {},
  mounted () {
    // window.goBack = this.goBack
  },
  created() {
    this.data.content = this.$route.query.content
    this.data.title = this.$route.query.title
    this.data.type = this.$route.query.type
    console.log(this.data)
    this.getList()
  },
  methods: {
    getList() {
      if (this.$route.query.type === '3') {
        API.getVodPlayInfo({videoid:this.$route.query.id }).then(res => {
          console.log(res)
          this.data.playUrls = res.message.data.playUrls
        })
      }
    },
    //监听视频播放完成
    addevenVidEnd() {
      //console.log('你好世界')
      var that = this;
      //播放结束获取当前播放的课时ID进行接口请求

      if (that.pindex == that.data.chapterList.length - 1) {
        //当前走到最后一个章节了
      } else {
        if (that.thindex == that.data.chapterList[that.pindex].sectionList.length - 1) {
          //当前走到最后一个课时了,需要跳到下一个章节
          //console.log('要去下一章了');
          that.pindex = that.pindex + 1;
          that.thindex = 0;
          that.selGroup.push(that.thindex);
          let itemdom = document.querySelectorAll('.catalogue .item');
          itemdom[that.pindex].classList.add('item_ons');
          //console.log(that.selGroup);
          let datas = that.data.chapterList[that.pindex].sectionList[that.thindex];

          that.clickPayInfo(datas, that.pindex, that.thindex);

        } else {
          //console.log(that.data.chapterList[that.pindex].sectionList[that.thindex].length)
          //就是课时累加进入到下一个环节中
          that.thindex = that.thindex + 1;
          //console.log(that.thindex);
          let datas = that.data.chapterList[that.pindex].sectionList[that.thindex];

          that.clickPayInfo(datas, that.pindex, that.thindex);
        }
      }


    },
    goBack() {
      this.$router.go(-1);
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar__title {
  color: #ffffff;
  font-weight: bold;
}
::v-deep .van-nav-bar__text {
  color: #ffffff;
}
::v-deep .van-nav-bar__arrow {
  color: #ffffff;
}
::v-deep .van-nav-bar__content {
  background-color: #212c64;
}
.btn {
  width: 100%;
  padding: 20px;
  text-align: right;
}
.control-item {


}
</style>
